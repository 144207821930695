import React from 'react';
import Layout from '../components/layout';

const Publications = () => {

    return (
        <Layout>
            <h1>Publications and podcasts</h1>
            <p>
                Over the past year and a half I have appeared on a few podcasts (all related to horse racing) 
                and written a number of analysis pieces (also all related to horse racing).  
                This page feautres a collection of all of the analysis I've done in addition to what appears in the blog section of this site.
            </p>
            <h2>Podcasts</h2>
            <ol>
                <li>
                <a href='https://inthemoneypodcast.com/kee-show-for-sunday-10-6/'>
                    Podcast from 2019 about Keeneland Racing
                </a>
                </li>
                <li>
                <a href="https://inthemoneypodcast.com/redboard-rewind-stableduel-special/">
                    Podcast from the 2019 Breeders' Cup - StableDuel primer
                </a>
                </li>
                <li>
                <a href="https://inthemoneypodcast.com/racingpicks-com-players-podcast-september-11-2020-queens-plate-edition/">
                    More StableDuel, this time for the 2020 Queens' Plate
                </a>
                </li>
            </ol>
            <h2>Data Publications</h2>
            <ol>
                <li>
                    <a href='https://www.paulickreport.com/horseplayers-category/millenial-bettor-u-s-racing-data-is-nothing-short-of-garbage/'>
                        Oped featured originally by the Thoroughbred Idea Foundation about Horse Racing data, my journey and frustrations
                    </a>
                </li>
                <li>
                <a href='https://inthemoneypodcast.com/racing-fact-check-down-the-hill-races/'>
                    My first ever piece from early 2019 on the downhill races from Santa Anita
                </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/breeders-cup-2019-jockeys-in-dirt-sprints/'>
                        Breeders' Cup 2019 dirt sprint analysis (jockeys)
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/breeders-cup-2019-jockeys-in-dirt-routes/'>
                        Breeders' Cup 2019 dirt route analysis (jockeys)
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/keeneland-fall-2019-race-shapes/'>
                        Fall 2019 Keeneland race design primer
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/keeneland-fall-2019-shippers/'>  
                        Fall 2019 Keeneland analysis of horses shipping from other tracks
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/keeneland-fall-2019-jockeys-and-trainers/'>
                        Fall 2019 Keeneland jockey and trainer primer
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/bing-crosby-race-9-del-mar-july-27th-2019/'>
                        Bing Crosby 2019 Fair Odds analysis
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/jim-dandy-race-11-saratoga-july-27th-2019/'>
                        Jim Dandy 2019 Fair Odds analysis
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/saratoga-summer-2019-meet-data-overview-chad-brown/'>
                        Saratoga Summer 2019 Chad Brown trainer analysis
                    </a>
                </li>
                <li>
                    <a href ='https://inthemoneypodcast.com/saratoga-summer-2019-meet-data-overview-running-style/'>
                        Saratoga Summer 2019 Running Style analysis
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/saratoga-summer-2019-meet-data-overview-2-year-old-races/'>
                        Saratoga Summer 2019 2 year old races analysis
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/saratoga-summer-2019-meet-data-overview-favorite-analysis/'>
                        Saratoga Summer 2019 Favoirte win rate analysis
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/kentucky-oaks-pace-and-fair-value-line/'>
                        Kentucky Oaks 2019 Pace and Fair Odds analysis
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/racing-fact-check-kentucky-derby-post-position-draws/'>
                        Kentucky Derby 2019 Post Position analysis (appeared in Brisnet Betting Guide on track at Churhill Downs in May of 2019)
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/keeneland-trainers-first-time-starters-and-layoffs'>
                        Keeneland Spring 2019 Trainers with First Time Starters analysis
                    </a>
                </li>
                <li>
                    <a href = 'https://inthemoneypodcast.com/keeneland-how-winners-won-races-race-evolution/'>
                        Keeneland Spring 2019 Race Shape analysis
                    </a>
                </li>
                <li>
                    <a href='https://inthemoneypodcast.com/keeneland-looking-at-the-spring-meet-through-data/'>
                        Keeneland Spring 2019 Meet Primer
                    </a>
                </li>
            </ol>
            <h2>Pieces by others I assisted with (for UK audiences)</h2>
            <ol>
                <li>
                    <a href='https://www.attheraces.com/blogs/peter-fornatale-blog/02-October-2020/meeting-preview-keeneland-fall'>
                        Keeneland 2020 Fall Meeting Preview
                    </a>
                </li>
                <li>
                    <a href ='https://www.attheraces.com/blogs/peter-fornatale-blog/24-July-2020/course-guide-woodbine'>
                        Woodbine Summer 2020 Meet Preview
                    </a>
                </li>
                <li>
                    <a href='https://www.attheraces.com/blogs/peter-fornatale-blog/16-July-2020/course-guide-saratoga'>
                        Saratoga Summer 2020 Meet Preview
                    </a>
                </li>
                <li>
                    <a href='https://www.attheraces.com/blogs/peter-fornatale-blog/10-July-2020/keeneland-preview'>
                        Keeneland Summer 2020 Meet Preview
                    </a>
                </li>
                <li>
                    <a href='https://www.attheraces.com/blogs/peter-fornatale-blog/03-June-2020/course-guide-santa-anita'>
                        Santa Anita Summer 2020 Meet Preview
                    </a>
                </li>
                <li>
                    <a href='https://www.attheraces.com/blogs/peter-fornatale-blog/11-June-2020/course-guide-belmont-park'>
                        Belmont Park Summer 2020 Meet Preview
                    </a>
                </li>
                <li>
                    <a href='https://www.attheraces.com/blogs/peter-fornatale-blog/20-May-2020/course-guide-golden-gate-fields'>
                        Golden Gate Fields Summer 2020 Meet Preview
                    </a>
                </li>
            </ol>
        </Layout>
    )
}

export default Publications;